
import { getTextFromEvent } from '~/utils/helpers'
import analytics from '~/mixins.js/analytics'

export default {
  data: () => ({
    isOpen: false
  }),
  components: {
    'id-header-categories': () => import('~/components/header/Categories.vue')
  },
  mixins: [analytics],
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    },
    trackClick (event) {
      const text = getTextFromEvent(event)
      this.trackEvent('Menu', text)
    }
  }
}
